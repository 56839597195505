import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Carousel from 'react-material-ui-carousel';
import { Paper, Grid, Divider, Slide } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  paper: {
    // width: '70%',
    // padding: '30px',

    border: '1px solid #e5e5e5',
    // borderRadius: '25px',
  },
  paperHeader: {
    display: 'flex',
    padding: '20px',
    margin: '0px',
    color: 'white',
    backgroundColor: '#32475b',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerElement: {
    margin: '0px',
  },
  gridContainer: {
    // background: 'linear-gradient(30deg, #e6ffff, #f0e6ff)',
    // background: 'linear-gradient(30deg, #ccffff,#ccffee, #e6ffff, #f0e6ff)',
    background: 'linear-gradient(30deg, #e6ffff,#cce6ff, #f0e6ff)',
  },
  tableHeader: {
    fontSize: '14px',
    fontWeight: 600,
    padding: '10px',
    backgroundColor: '#00b3b3',
    color: 'white',
  },
  tableBody: {
    padding: '10px',
  },
  tableFooter: {},
  subHeader: {
    width: '100%',
  },
}));

{
  //https://learus.github.io/react-material-ui-carousel/
  // https://www.npmjs.com/package/react-material-ui-carousel
  /* <LinearGradient
        colors={['red', 'yellow', 'green' ]}
        style={styles.linearGradient}
        start={{ x: 0.7, y: 0 }}
      >
        <Text>Diagonal Gradient</Text>
      </LinearGradient> */
}

const homePage = [
  {
    businesse: 'Small Businesses upto 5 Cr',
    transactionRange: 'Monthly Transactions(0-50)',
    particulars: [
      { particular: 'Accounting & MIS Charges', transaction: 4999 },
      {
        particular: 'GSTR1 & GSTR3B upto 15 Transactions Monthly',
        transaction: 499,
      },
      { particular: 'Payroll upto 9 employees Monthly', transaction: 2000 },
      { particular: 'CFO Consulting upto 2 hours Monthly', transaction: 2500 },
    ],
    totalTransaction: 9998,
  },
  {
    businesse: 'Medium Businesses  5 Cr to 25 Cr',
    transactionRange: 'Monthly Transactions(0-100)',
    particulars: [
      { particular: 'Accounting & MIS Charges', transaction: 10000 },
      {
        particular: 'GSTR1 & GSTR3B upto 50 Transactions Monthly',
        transaction: 1500,
      },
      { particular: 'Payroll upto 9 employees Monthly', transaction: 2000 },
      { particular: 'CFO Consulting upto 4 hours Monthly', transaction: 5000 },
    ],
    totalTransaction: 18500,
  },
  {
    businesse: 'Income Tax Return Filling',
    transactionRange: '',
    particulars: [
      { particular: 'ITR 1* (Sahaj)', transaction: 499 },
      {
        particular: 'ITR 2 & ITR 3',
        transaction: 999,
      },
      { particular: 'ITR 4 & ITR 5', transaction: 1999 },
      { particular: 'Filing of TDS/TCS Returns', transaction: 1499 },
    ],
  },
];

export default function Home() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth='sm'>
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='textPrimary'
              gutterBottom
            >
              Start With Us!
            </Typography>
            <Typography
              variant='h5'
              align='center'
              color='textSecondary'
              paragraph
            >
              Start with us! We are here for all your tax solution. Let's get
              together and grow up!
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth='md'>
          <Carousel autoPlay={false} interval={3000}>
            {homePage.map((item, i) => {
              return (
                <Paper className={classes.paper} square={false} elevation={3}>
                  <div className={classes.paperHeader}>
                    <h2 className={classes.headerElement}>{item.businesse}</h2>
                    <h5 className={classes.headerElement}>
                      {item.transactionRange}
                    </h5>
                    {/* <Typography>
                      <h2>{item.businesse}</h2>
                    </Typography>
                    <Typography>
                      <h5>{item.transactionRange}</h5>
                    </Typography> */}
                  </div>
                  {/* <Divider /> */}
                  <Grid container className={classes.gridContainer}>
                    {/* <div className={classes.tableHeader}> */}
                    <Grid item xs={12} sm={6} className={classes.tableHeader}>
                      Particulars
                    </Grid>
                    <Grid className={classes.tableHeader} item xs={12} sm={6}>
                      Amount
                    </Grid>
                    {/* </div> */}
                    {item.particulars.map((particular) => {
                      return (
                        <>
                          {' '}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.tableBody}
                          >
                            {particular.particular}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.tableBody}
                          >
                            {particular.transaction}
                          </Grid>
                          <Divider />
                        </>
                      );
                    })}
                    {/* <div className={classes.tableFooter}> */}
                    {item.totalTransaction ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.tableHeader}
                        >
                          Total
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.tableHeader}
                        >
                          {item.totalTransaction}
                        </Grid>
                      </>
                    ) : null}

                    {/* </div> */}
                  </Grid>
                </Paper>
              );
            })}
          </Carousel>
        </Container>
      </main>
    </React.Fragment>
  );
}
