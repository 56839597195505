import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Home from './Home';
import AboutUs from './AboutUs';
import Pricing from './Pricing';
import Services from './Services';
import ContactUs from './ContactUs';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link style={{ color: '#26cdff' }}>StartupAccountax.com</Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#1d2e3e', //'#033d75',
    color: 'white',
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: 'white',
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#1d2e3e', //'#033d75',
    color: 'white',
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  footerLink: {
    link: {
      margin: theme.spacing(1, 1.5),
      color: '#26cdff',
    },
    location: {
      color: 'white',
      fontSize: '0.875rem',
      fontWeight: 400,
      paddingLeft: '10px',
    },
  },
  footerList: {
    padding: '0px',
  },
  footerListItem: {
    padding: '0px',
  },
  footerTitle: {
    paddingLeft: '10px',
  },
  contactDetails: {
    fontSize: '0.875rem',
    fontWeight: 400,
    fontStyle: 'italic',
    textDecoration: 'underline',
  },
  contactDetailsHeader: {
    fontSize: '0.875rem',
    fontWeight: 400,
    fontStyle: 'italic',
  },
  footerContactInfo: {
    paddingLeft: '10px',
    paddingTop: '10px',
    fontSize: '0.875rem',
    fontWeight: 400,
  },
}));

const footers = [
  {
    title: 'Go Quick',
    description: [
      { title: 'Home', link: 'Home' },
      { title: 'About Us', link: 'AboutUs' },
      { title: 'Services', link: 'Services' },
      { title: 'Pricing', link: 'Pricing' },
      // { title: 'Contact Us', link: 'ContactUs' },
    ],
  },
  {
    title: 'Contact Us',
    description: [
      'Flat No. 4, 2nd Floor, Omkar Apartment, Survey No. 275 A/B, Shukrawar Peth, Madhukar Chimanrav Potnis Road, Near Chichechi Talim, Pune-411002.',
    ],
    mailId: 'sat.reachus@gmail.com, reachus@startupaccountax.com',
    landlineNo: '020-24455006',

    // description: [
    //   'Flat No. 4, 2nd Floor, Omkar Apartment,',
    //   'Survey No. 275 A/B, Shukrawar Peth,',
    //   'Madhukar Chimanrav Potnis Road, ',
    //   'Near Chichechi Talim, Pune-411002.',
    // ],
  },
];

export default function Dashboard() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Router>
        <CssBaseline />
        <AppBar
          position='static'
          color='red'
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              className={classes.toolbarTitle}
            >
              StartupAccountax
            </Typography>
            {/*<nav>*/}
            <Link
              variant='button'
              color='textPrimary'
              to='./Home'
              className={classes.link}
            >
              Home
            </Link>
            <Link
              variant='button'
              color='textPrimary'
              to='./AboutUs'
              className={classes.link}
            >
              About Us
            </Link>
            <Link
              variant='button'
              color='textPrimary'
              to='./Services'
              className={classes.link}
            >
              Services
            </Link>
            <Link
              variant='button'
              color='textPrimary'
              to='./Pricing'
              className={classes.link}
            >
              Pricing
            </Link>
            {/* <Link
              variant='button'
              color='textPrimary'
              to='./ContactUs'
              className={classes.link}
            >
              Contact Us
            </Link> */}

            {/*</nav> */}

            {/*<Button href="#" color="primary" variant="outlined" className={classes.link}>Login</Button>*/}
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path='/Home' component={Home} />
          <Route path='/AboutUs' component={AboutUs} />
          <Route path='/Services' component={Services} />
          <Route path='/Pricing' component={Pricing} />
          {/* <Route path='/ContactUs' component={ContactUs} /> */}
          <Route path='/' component={Home} />
        </Switch>
        {/* Footer */}
        <Container elevation={0} component='footer' className={classes.footer}>
          <Grid container spacing={3} justify='space-evenly'>
            {footers.map((footer) => (
              <Grid item xs={12} sm={6} key={footer.title}>
                <Typography
                  variant='h6'
                  gutterBottom
                  className={classes.footerTitle}
                >
                  {footer.title}
                </Typography>
                {footer.title !== 'Contact Us' ? (
                  <List className={classes.footerList}>
                    {footer.description.map((value) => {
                      return (
                        <ListItem className={classes.footerListItem}>
                          <Link
                            variant='button'
                            to={value.link}
                            className={classes.link}
                          >
                            {value.title}
                          </Link>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : null}
                {footer.title === 'Contact Us' &&
                  footer.description.map((addLines) => {
                    return (
                      <div className={classes.footerTitle}>
                        <Typography className={classes.location}>
                          {addLines}
                        </Typography>
                      </div>
                    );
                  })}
                {footer.title === 'Contact Us' ? (
                  <div className={classes.footerContactInfo}>
                    <Typography className={classes.contactDetailsHeader}>
                      Email Us :
                      <span className={classes.contactDetails}>
                        {' '}
                        {footer.mailId}
                      </span>
                    </Typography>
                    <Typography className={classes.contactDetailsHeader}>
                      Landline No :
                      <span className={classes.contactDetails}>
                        {' '}
                        {footer.landlineNo}
                      </span>
                    </Typography>
                  </div>
                ) : null}
              </Grid>
            ))}
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
        {/* End footer */}
      </Router>
    </React.Fragment>
  );
}
