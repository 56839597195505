import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import OpenWithIcon from '@material-ui/icons/OpenWith';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },

  panelHeader: {
    backgroundColor: '#033d75',
    color: 'white',
  },
  header: {
    padding: '12px 24px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: '#e6f2ff',
  },
  listElement: {
    padding: '12px 24px',
  },
  listitem: {
    padding: '0px',
  },
  listIcon: {
    minWidth: 'auto',
  },
  listItemText: {
    paddingLeft: '5px',
  },
});

export default function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, priceDetails, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  // const handleOpen = () => {
  //   console.log('selectedValue: ', selectedValue);
  // };
  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      TransitionComponent={Transition}
      keepMounted
      BackdropProps={{
        style: { backgroundColor: 'rgba(0,0,0,0.2)', boxShadow: 'none' },
      }}
    >
      <DialogTitle id='simple-dialog-title' className={classes.panelHeader}>
        {selectedValue.title}
      </DialogTitle>
      <Divider />
      <div>
        <Grid container>
          {priceDetails && priceDetails.length > 0
            ? priceDetails.map((service) => {
                return (
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.header}>{service.subService}</div>
                    <List className={classes.listElement}>
                      {service.serviceList.map((value) => {
                        return (
                          <ListItem className={classes.listitem}>
                            <ListItemIcon className={classes.listIcon}>
                              <OpenWithIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={value}
                              className={classes.listItemText}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                );
              })
            : null}
        </Grid>
      </div>
    </Dialog>
  );
}
