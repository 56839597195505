import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TrendingUp from '@material-ui/icons/TrendingUp';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const serviceList = [
  'Online Bookkeeping',
  'Streamlined Bill Pay',
  'Full-Service Payroll',
  'Financial Statement Preparation',
  'Tax Preparation and Advising',
  'Financial Planning',
  'Audit Assistance',
  'Budget Preparation',
  'Job Costing',
  'Registrations of Businesses & Licenses with Government',
  'And Much, Much More',
];

export default function AboutUs() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth='sm'>
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='textPrimary'
              gutterBottom
            >
              Who We Are?
            </Typography>
            <Typography
              variant='h5'
              align='center'
              color='textSecondary'
              paragraph
            >
              Startupaccountax.com is your all-in-one Startup business solution
              for accounting, bookkeeping, payroll, bill pay, and tax.
            </Typography>
          </Container>
        </div>
        <div className={classes.heroContent}>
          <Container className={classes.cardGrid} maxWidth='md'>
            <Typography color='textSecondary' paragraph>
              Welcome to Startupaccountax.com. We've been in business for over
              15 years, so we've seen a thing or two.
            </Typography>
            <Typography color='textSecondary' paragraph>
              Our experience and progressive system using best of breed
              technology allow us to securely provide bookkeeping and accounting
              services anywhere you are.
            </Typography>
            <Typography color='textSecondary' paragraph>
              Startupaccountax.com is not a technology company - we are real
              accountants partnering with your small business. The more services
              we do for you, the better we know you, the better we can serve
              you.
            </Typography>
            <Typography color='textSecondary'>
              <List className={classes.root}>
                {serviceList.map((value) => {
                  return (
                    <ListItem>
                      <ListItemIcon>
                        <TrendingUp />
                      </ListItemIcon>
                      <ListItemText primary={value} />
                    </ListItem>
                  );
                })}
              </List>
            </Typography>
            <Typography color='textSecondary' paragraph>
              When you become a Startupaccounts.com client, you will have access
              to a dedicated team that will look after your business interests
              as if they were their own. Whether the Small Business Plan is best
              for you, or Small Business Plus is the better fit, Bookkeeper.com
              can provide the financial tools and reporting necessary to enable
              you to know your financial standing, and focus on what is most
              important -- growing your business and making the best decisions
              for your financial needs. Costs are modest and always agreed to in
              advance, so there are never any surprise fees. We are committed to
              the satisfaction of each and every client, which is why our
              greatest source of new accounts comes from existing clients.
            </Typography>
            <Typography variant='h6' color='textSecondary' paragraph>
              Give us a call today, and let’s start a conversation!
            </Typography>
          </Container>
        </div>
      </main>
    </React.Fragment>
  );
}
