import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import TrendingUp from '@material-ui/icons/TrendingUp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
  },
  accordion: {
    paddingBottom: '10px',
  },
}));

const services = [
  {
    title: 'Business set-up services to India',
    description: [
      'Do you have a dream of starting your own business in India the Land of growing opportunities and finding problems with the company registration process? Team Startupaccoutax.com with its expertise offers the best services which are structured to help you achieve business goals successfully.',
      'At Startupaccoutax.com we follow a professional approach of attending to our clients. We make it a point to understand your needs and provide the best possible solutions that will help you get satisfactory results. We aim to provide top-notch services which are cost-effective as well.',
      ' Our staff is friendly, approachable and capable enough to attend to your concerns and lead you through the entire new company registration process with ease.',
    ],
    subServices: [
      'Company, LLP, Partnership Registration in India',
      'PAN Registration',
      'TAN Registration',
      'GST Registration',
      'MSME Registration/ Udyog Aadhar Registration',
      'Import Export Registration',
      'Employer Provident Fund Registration',
      'ESIC Registrations',
      'Labour Welfare Fund Registrations',
      'Professional Tax Registrations',
    ],
  },
  {
    title: 'Accounting Services / Bookkeeping Services',
    description: [
      'Accounting refers to the process of keeping financial records pertaining to the business. It involves the process of recording, summarizing, analysing and reporting all the transactions. Accounting is one of the most important parts of a company without which the operations cannot be structured properly. Any kind of business entity is a startup or an existing one needs to have a channelized accounting system for consistent business growth. We offer expertise in the areas of Accounting / Bookkeeping.',
    ],
    subServices: [
      'Accounts Receivable Management',
      'Accounts Payable Management',
      'Revenue Recognitions',
      'Expenses Recognitions',
      'Accounting Accrual / Cash Method',
      'Depreciation, Prepaid Expenses & Payable Expenses Management',
      'Bank Entries & Reconciliation',
      'Monthly Closing, Budgeting & MIS',
    ],
  },
  {
    title: 'Payroll Management Services',
    description: [
      'When you want to go beyond the mentality of just giving out paychecks to your employees, our payroll services can bring about new business insights and help you focus on what you do best - running your own business. Our payroll management services allow you to take your human resources management to a higher level.',
      'As one of the top payroll providers in India, we understand how important it is for your organisation to ensure error-free payroll management activities. With our extensive range of services, your organisation can enjoy a payroll process which is timely and accurate.',
      'Here are some of the payroll services that we provide:',
    ],
    subServices: [
      'Payroll Processing & Bank file preparation',
      'Designing Pay Structure',
      'Payslip to individual employees',
      'Form 16 to Individual employees',
      'Provident Fund return filing',
      'ESIC return filing',
      'Professional Tax return filing',
      'Income Tax calculation of Individual employees with Investment Proof',
    ],
  },
  {
    title: 'Tax Management Services',
    description: [
      'Our professional staff, with their wealth of knowledge and expertise, can assist you with any and all of your tax issues. With over decade of experience behind us, our qualified team of tax professionals is at your service. So come, let us help you with your personal & business taxes.',
    ],
    subServices: [
      'Income Tax Return filing',
      'GST Return filing',
      'TDS Return filing',
      'Tax Planning',
      'Tax assessment, representation and litigations',
    ],
  },
  {
    title: 'Business Management Advisory & Assurance',
    description: [
      'As one of the experienced accounting and advisory networks, we help a diverse range of clients with a diverse range of needs. This is especially true of our Advisory Practice, which provides an array of regulatory, risk and executive services to clients navigating an exceptional range of challenges, changes, and opportunities.',
      'Our professionals are drawn from a variety of multidisciplinary backgrounds, allowing us to deliver insightful, innovation solutions – no matter the challenge, no matter the need.',
    ],
    subServices: [
      'Internal Audit',
      'Stock Audit',
      'Project Finance Reports',
      'Project Profitability Analysis',
      'Cost Control & Reduction Analysis',
      'Drafting Standard Operating Procedure for Businesses',
      'Fixed Asset Management',
      'Virtual CFO Services',
    ],
  },
  {
    title: 'International Outsourcing ',
    description: [
      'As a CPA you are aware about challenges of delivering services to your clients with the help of experienced and skilled staff and juggling for maintaining staff in an affordable salary and after that focusing on main business activities. To overcome all these challenges we have solutions for everything.',
      'Our accounting and bookkeeping services for CPAs can be customized to fulfil your firm’s needs. Our role can easily change, as required.',
    ],
    subServices: [
      'Bookkeeping for CPA Firms',
      'Financial Statement Preparation',
      'Sales and Use Tax Preparation',
      'QuickBooks Pro Advisor',
      'Write-up Services',
    ],
  },
  {
    title: 'Compliances with ROC',
    description: [
      'Our professional staff, with their wealth of knowledge and expertise, can assist you with any and all of your tax issues. With over decade of experience behind us, our qualified team of tax professionals is at your service. So come, let us help you with your personal & business taxes.',
    ],
    subServices: [
      'a.  Audit Report Filing with MCA',
      'DIN KYC',
      'All compliances related to MCA with nature of entity.',
    ],
  },
];
export default function Services() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth='sm'>
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='textPrimary'
              gutterBottom
            >
              Services
            </Typography>
            <Typography
              variant='h5'
              align='center'
              color='textSecondary'
              paragraph
            >
              Startupaccountax.com is your all-in-one Startup business solution
              for accounting, bookkeeping, payroll, bill pay, and tax.
            </Typography>
          </Container>
        </div>
        <div className={classes.heroContent}>
          <Container className={classes.cardGrid} maxWidth='md'>
            <div className={classes.root}>
              {services.map((service, index) => {
                return (
                  <div className={classes.accordion}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1c-content'
                        id='panel1c-header'
                      >
                        <div className={classes.column}>
                          <Typography
                            variant='h5'
                            align='center'
                            color='textPrimary'
                          >
                            {index + 1}.&nbsp;{service.title}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={classes.details}>
                        <div className={clsx(classes.column, classes.helper)}>
                          {service.description.map((description) => {
                            return (
                              <Typography
                                className={classes.secondaryHeading}
                                paragraph
                              >
                                {description}
                              </Typography>
                            );
                          })}
                          <Typography color='textSecondary'>
                            <List className={classes.root}>
                              {service.subServices.map((value) => {
                                return (
                                  <ListItem>
                                    <ListItemIcon>
                                      <TrendingUp />
                                    </ListItemIcon>
                                    <ListItemText primary={value} />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Typography>
                        </div>
                      </AccordionDetails>
                      <Divider />
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </Container>
        </div>
      </main>
    </React.Fragment>
  );
}
