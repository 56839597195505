import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SimpleDialog from './SimpleDialog';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: '#cde6fe  ',
    fontSize: '0.5rem',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'Income Tax Return Filling',
    price: '499',
    buttonText: 'Get Into More',
    buttonVariant: 'outlined',
    priceDetails: [
      {
        subService: 'ITR 1* (Sahaj) @ 499',
        serviceList: [
          'Income from salary/pension (for ordinarily resident person)',
          'Income or loss from one house property (excluding brought forward losses and losses to be carried forward)',
        ],
      },
      {
        subService: 'ITR 2 & ITR 3 starts @999',
        serviceList: [
          'Salary Income',
          'Income from House Property',
          'Capital Gains',
          'Income from Other Sources',
        ],
      },
      {
        subService: 'ITR 4 & ITR 5 starts @ 1999',
        serviceList: [
          'Firm (excluding LLPs) opting for presumptive taxation scheme of section 44AD, 44ADA or 44AE',
          'Firm (including LLPs)',
        ],
      },
      {
        subService: 'Filing of TDS/TCS Returns starts @ 1499',
        serviceList: [
          'Filing of 24Q',
          'Filing of 26Q',
          'Filing of 27Q',
          'Filing of 27EQ',
        ],
      },
    ],
  },
  {
    title: 'Accounting & Reporting',
    price: '4999',
    buttonText: 'Get Into More',
    buttonVariant: 'outlined',
    priceDetails: [
      {
        subService: 'Accounting & MIS Reporting starts @ 4999',
        serviceList: [
          'Accounts Payable Function',
          'Accounts Receivable Function',
          'Accounts Reconciliation',
          'Finalization of Accounts',
          'MIS',
        ],
      },
      {
        subService: 'Virtual CFO Advisory starts @ 3000',
        serviceList: [
          'Monthly Meeting and Advisory for Improvement of Business and profitability.',
        ],
      },
    ],
  },
  {
    title: 'Payroll',
    price: '2000',
    description: [
      'Payroll processing with bank transfer and Payslips with Tax calculations',
    ],
    buttonText: 'Get Into More',
    buttonVariant: 'outlined',
    priceDetails: [
      {
        subService: 'Monthly Payroll Package starts @2000',
        serviceList: [
          'Maintaining payroll records',
          'Payment of withholding taxes',
          'Payroll reports',
          'Payment of withholding taxes',
        ],
      },
      {
        subService: 'Professional Tax Return filing starts @2000',
        serviceList: ['Filing of Professional Tax Returns'],
      },
      {
        subService: 'Provident Fund Return filing starts @2000',
        serviceList: ['Filing of Provident Fund Returns'],
      },
      {
        subService: 'ESIC Return filing starts @2000',
        serviceList: ['Filing of ESIC Returns'],
      },
    ],
  },
  {
    title: 'GST Return Filling',
    price: '499',
    buttonText: 'Get Into More',
    buttonVariant: 'outlined',
    priceDetails: [
      {
        subService: 'GST Monthly Return filing starts @499',
        serviceList: ['GSTR 1 Filing', 'GSTR3B Filing', 'GSTR7 filing'],
      },
      {
        subService: 'GST Annual Return filing starts @4999',
        serviceList: ['GSTR 9 & GSTR9C filing'],
      },
    ],
  },
  {
    title: 'Annual ROC Compliance',
    price: '7000',
    buttonText: 'Get Into More',
    buttonVariant: 'outlined',
    priceDetails: [
      {
        subService: 'ROC filing package starts @7000',
        serviceList: ['LLP Annual Filing', 'Company Annual filing'],
      },
      { subService: 'DIN KYC starts @900', serviceList: ['Yearly DIN KYC'] },
    ],
  },
  {
    title: 'Business Registrations',
    price: '5000',
    buttonText: 'Get Into More',
    buttonVariant: 'outlined',
    priceDetails: [
      {
        subService: 'Business Registration starts @5000',
        serviceList: [
          'Company Registration',
          'LLP Registration',
          'Partnership Registration',
          'MSME Registration/ Udyog Aadhar',
          'Shop Act Registration',
        ],
      },
      {
        subService: 'Statutory Business Registrations Package starts @5000',
        serviceList: [
          'PAN Registration',
          'TAN Registration',
          'GST Registration',
          'IEC Registration (Import & Export Registration, IGATE Registration',
          'PTRC & PTEC Registration',
          'Provident Fund Registration',
          'ESIC Registration',
        ],
      },
    ],
  },
];

export default function Pricing() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const handleClickOpen = (value) => {
    setSelectedValue(value);
    setOpen(true, value);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth='sm' component='main' className={classes.heroContent}>
        <Typography
          component='h1'
          variant='h2'
          align='center'
          color='textPrimary'
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography
          variant='h5'
          align='center'
          color='textSecondary'
          component='p'
        >
          Quickly build an effective pricing table for your potential customers
          with this layout. It&apos;s built with default Material-UI components
          with little customization.
        </Typography>
      </Container>
      <Container maxWidth='md' component='main'>
        <Grid container spacing={5} alignItems='flex-end'>
          {tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center', variant: 'h6' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div>
                    <Typography
                      variant='h6'
                      color='textSecondary'
                      align='center'
                    >
                      Starts @
                    </Typography>
                  </div>
                  <div className={classes.cardPricing}>
                    <Typography component='h2' variant='h3' color='textPrimary'>
                      {tier.price}
                    </Typography>
                    <Typography variant='h6' color='textSecondary'>
                      INR
                    </Typography>
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color='primary'
                    onClick={() => handleClickOpen(tier)}
                  >
                    {tier.buttonText}
                  </Button>
                  <SimpleDialog
                    selectedValue={selectedValue}
                    priceDetails={selectedValue.priceDetails}
                    open={open}
                    onClose={handleClose}
                  />
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
